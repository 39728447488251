import { NavLink } from "react-router-dom";

const NavBar = () => {
  return (
    <>
      <NavLink to="/home" style={{textDecoration: "none"}}>home</NavLink>
      <NavLink to="/about" style={{textDecoration: "none"}}>about</NavLink>
      {/* <NavLink to="/resume" style={{textDecoration: "none"}}>resume</NavLink> */}
      <NavLink to="/contact" style={{textDecoration: "none"}}>contact</NavLink>
    </>
  )
};

export default NavBar;