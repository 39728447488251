import "./App.css";
import { Route } from "react-router-dom";
import Home from "../Home";
import About from "../About";
// import Resume from "../Resume";
import Contact from "../Contact";
import NavBar from "../NavBar";

const App = () => {
  return (
    <div>
      <section className="navigation">
        <NavBar />
      </section>
      <>
        <Route path="/home"><Home /></Route>
        <Route path="/about"><About /></Route>
        {/* <Route path="/resume"><Resume /></Route> */}
        <Route path="/contact"><Contact /></Route>
      </>
    </div>
  );
}

export default App;



// I design and develop
// colors: yellow?
// quote? / tag line?
// get new photos
// how can I be experimental?
// share my location: Colorado, USA
// button highlights on current page
// maybe use a hover effect
// how can I incorporate animations?
// make a logo / maybe with my initials
// create a color scheme
// use a vertical scroll!

// let it breathe
// tip the scale
// elements: earth, air, water, fire


// include instagram, linkedin, GH links
