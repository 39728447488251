import { useState } from "react";
import "../components/App/App.css";

const Contact = () => {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userMessage, setUserMessage] = useState("");

  const handleSubmit = () => {
    const userInfo = {
      name: userName,
      email: userEmail,
      message: userMessage
    }
  }

  return (
    <div className="form-container">
      <h2>Reach out</h2>
      <form className="form-wrapper">
        <label for="name">Name</label>
        <input 
          type="text" 
          id="name" 
          name="userName" 
          value={userName} 
          onChange={(e) => setUserName(e.target.value)}
          placeholder="your name"
        />
        <label for="email">Email</label>
        <input 
          type="text" 
          id="email" 
          name="userEmail" 
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
          placeholder="your email"
        />
        <label for="message">Message</label>
        <input 
          type="text" 
          id="message" 
          name="userMessage" 
          value={userMessage}
          onChange={(e) => setUserMessage(e.target.value)}
          placeholder="Message goes here"
        />
        <button>Send!</button>
      </form>
    </div>
  )
};

export default Contact;